<template>
  <div class="vod">
    <div class="intro">
      <p>
        Voici la liste des précédents directs chargés directement depuis Twitch.<br>
        Prennez note qu'ils sont automatiquement supprimés après une certaine durée.
      </p>
    </div>
    <div
      v-if="videos.length === 0"
      class="loading">
      Chargement...
    </div>
    <div class="videos">
      <div
        v-for="v in videos"
        :key="v.broadcast_id"
        class="video">
        <a :href="v.url">
          <img :src="v.preview.medium"><br>
          <div class="details">
            <b>{{ v.title }}</b><br>
            {{ v.game }}<br>
            <small>
            {{ vlen(v.length) }} -
            {{ vdatetime(v.published_at) }}<br>
            </small>
            <small v-if="v.delete_at">
              Dispo' jusqu'au {{ vdate(v.delete_at) }}
            </small>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'vod',
  data () {
    return {
      videos: []
    }
  },
  mounted () {
    const clientId = '5cb8yick9uln3wu4tnzecr13pavaq0'
    const url = 'https://api.twitch.tv/kraken/channels/456444689/videos'
    const params = '?limit=100&broadcast_type=archive'

    fetch(url + params, {
      headers: {
        Accept: 'application/vnd.twitchtv.v5+json',
        'Client-ID': clientId
      }
    })
      .then(r => r.json())
      .then(j => {
        this.videos = j.videos
      })
  },
  methods: {
    vlen: function (s) {
      const date = new Date(null)
      date.setSeconds(s)
      return date.toISOString().substr(11, 8)
    },
    vdatetime: function (d) {
      const date = new Date(d)
      return date.toLocaleString('fr-CH', {
        weekday: 'long',
        day: 'numeric',
        month: 'short',
        hour: '2-digit',
        minute: '2-digit'
      })
    },
    vdate: function (d) {
      const date = new Date(d)
      return date.toLocaleString('fr-CH', {
        weekday: 'long',
        day: 'numeric',
        month: 'short'
      })
    }
  }
}
</script>

<style lang="scss">
.vod {
  .intro {
    line-height: 1;
    padding: 5px 15px;

    a,
    a:visited {
      color: lightblue;
    }
  }

  .loading {
    font-size: 2em;
    font-style: oblique;
    margin: 25px auto;
    text-align: center;
  }

  .videos {
    .video {
      border-top: 1px solid #555555;

      a {
        align-items: center;
        color: inherit;
        display: flex;
        text-decoration: none;

        img {
          height: auto;
          max-width: 210px;
          width: 25%;
        }

        .details {
          line-height: 1.1;
          padding: 5px 10px;
        }
      }
    }
  }
}
</style>
